import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { BsPatchCheckFill } from "react-icons/bs";

import "./Experience.css";

const Experience = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section
      data-aos={window.innerWidth <= 600 ? "fade-right" : ""}
      id="experience"
    >
      <h5 data-aos="zoom-in">What Skills I Have</h5>
      <h2 data-aos="zoom-in">My Experience</h2>

      <div
        data-aos={window.innerWidth <= 600 ? "fade-right" : "zoom-out"}
        className="container experience__container"
      >
        <div
          data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-right"}
          className="experience__frontend"
        >
          <h3 data-aos="fade-down" data-aos-delay="250">
            Frontend Development
          </h3>
          <div className="experience__content">
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-right"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-right"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-right"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-right"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Bootstrap</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-right"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>React.js</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div>
        <div
          data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-left"}
          className="experience__backend"
        >
          <h3 data-aos="fade-down" data-aos-delay="250">
            Backend Development
          </h3>
          <div className="experience__content">
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-left"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Node.js</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-left"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>Express.js</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-left"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>MongoDB</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-left"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>MySQL</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-left"}
              data-aos-delay="350"
              className="experience__details"
            >
              <BsPatchCheckFill className="experience__details-icon" />
              <div>
                <h4>REST API</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
