import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

import "./Header.css";
import CTA from "./CTA";
import ME from "../../assets/code-pic.svg";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <header id="/">
      <div
        data-aos={window.innerWidth <= 600 ? "zoom-out" : "slide-right"}
        className="container header__container"
      >
        <h5
          data-aos={window.innerWidth <= 600 ? "flip-up" : "fade-right"}
          data-aos-delay={window.innerWidth <= 600 ? "500" : "1000"}
        >
          Hello I'm
        </h5>
        <h1
          data-aos={window.innerWidth <= 600 ? "flip-up" : "fade-right"}
          data-aos-delay={window.innerWidth <= 600 ? "500" : "1000"}
        >
          Mahdi Raghah
        </h1>
        <h5
          data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-right"}
          data-aos-delay={window.innerWidth <= 600 ? "800" : "1600"}
          className="text-light"
        >
          Fullstack Developer
        </h5>
        <CTA />
        <HeaderSocials />

        <div
          data-aos={window.innerWidth <= 600 ? "flip-down" : "zoom-in"}
          data-aos-delay={window.innerWidth <= 600 ? "800" : "1600"}
          className="me"
        >
          <img src={ME} alt="me" />
        </div>

        <Link className="scroll__down" activeClass="active" spy to="contact">
          Contact Me
        </Link>
      </div>
    </header>
  );
};

export default Header;
