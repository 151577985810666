import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import AOS from "aos";
import "aos/dist/aos.css";
// import { MdOutlineEmail } from "react-icons/md";
import ContactImg from "../../assets/contact.svg";

import "./Contact.css";

const Contact = () => {
  const form = useRef();
  const [responseMessage, setResponseMessage] = useState("");

  let timer1 = setTimeout(() => setResponseMessage(true), 5000);
  useEffect(() => {
    return () => {
      AOS.init({ duration: 2000 });
      clearTimeout(timer1);
    };
  }, [responseMessage, timer1]);

  const sendEmailHandler = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_bm1lbbd",
        "template_sum4nix",
        form.current,
        "02i3UKHcgyCesbRhp"
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            setResponseMessage("Thanks for contacting me!");
          }
        },
        (error) => {
          if (!error.text === "OK") {
            setResponseMessage("Something went wrong!");
          }
        }
      );
    e.target.reset();
  };

  return (
    <section
      data-aos={window.innerWidth <= 600 ? "fade-right" : ""}
      id="contact"
    >
      <h5 data-aos="zoom-in">Contact</h5>
      <h2 data-aos="zoom-in">Get In Touch</h2>

      <div
        data-aos={window.innerWidth <= 600 ? "fade-right" : "zoom-out"}
        className="container contact__container"
      >
        <div
          data-aos="fade-right"
          data-aos-delay={window.innerWidth <= 600 ? "400" : "800"}
          className="contact__options"
        >
          <article className="contact__option">
            <img src={ContactImg} alt="Contact" />
          </article>
        </div>
        <form
          data-aos={window.innerWidth <= 600 ? "zoom-in" : "fade-left"}
          ref={form}
          onSubmit={sendEmailHandler}
        >
          <input
            data-aos="flip-up"
            data-aos-delay={window.innerWidth <= 600 ? "500" : "1000"}
            type="text"
            name="name"
            placeholder="Your Name"
            autoComplete="name"
            required
          />
          <input
            data-aos="flip-up"
            data-aos-delay={window.innerWidth <= 600 ? "500" : "1000"}
            type="email"
            name="email"
            placeholder="Your Email"
            autoComplete="home email"
            required
          />
          <textarea
            data-aos="flip-up"
            data-aos-delay={window.innerWidth <= 600 ? "500" : "1000"}
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          ></textarea>
          <button
            data-aos="flip-up"
            data-aos-delay={window.innerWidth <= 600 ? "600" : "1200"}
            type="submit"
            className="btn btn-primary"
          >
            Send Message
          </button>
          {responseMessage === "Thanks for contacting me!" && (
            <p id="form_message">{responseMessage}</p>
          )}
          {responseMessage === "Something went wrong!" && (
            <p id="form_error_message">{responseMessage}</p>
          )}
        </form>
      </div>
    </section>
  );
};

export default Contact;
