import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
// import { FaAward } from "react-icons/fa";
// import { FiUsers } from "react-icons/fi";
// import { VscFolderLibrary } from "react-icons/vsc";

import "./About.css";

import ME from "../../assets/me.png";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section data-aos={window.innerWidth <= 600 ? "fade-right" : ""} id="about">
      <h5 data-aos="zoom-in">Get To Know Me</h5>
      <h2 data-aos="zoom-in">About Me</h2>

      <div
        data-aos={window.innerWidth <= 600 ? "fade-right" : ""}
        className="container about__container"
      >
        <div data-aos="fade-right" className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Me" />
          </div>
        </div>
        <div data-aos="zoom-in" className="about__content">
          {/* <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>3+ Years Working</small>
            </article>

            <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>100+ Worldwide</small>
            </article>

            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>60+ completed</small>
            </article>
          </div> */}
          <p>
            A highly skilled, hardworking programmer who is ambitious,
            motivated, a fast learner and has a passion for developing scalable
            web applications. Well-organized person, problem solver, independent
            employee with high attention to detail and strong collaboration
            skills. Fan of MMA, outdoor activities, TV series and English
            literature. Interested in the entire development spectrum and
            working on ambitious projects with positive people.
          </p>

          <Link
            data-aos="zoom-in"
            data-aos-delay="350"
            className="btn btn-primary"
            activeClass="active"
            smooth
            spy
            to="contact"
          >
            Let's Talk
          </Link>
        </div>
      </div>
    </section>
  );
};

export default About;
