import React from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";

const HeaderSocials = () => {
  AOS.init({ duration: 2000 });

  return (
    <div data-aos="fade-right" data-aos-delay="350" className="header__socials">
      <a
        href="https://www.linkedin.com/in/mahdi-raghah"
        target="_blank"
        rel="noreferrer"
      >
        <BsLinkedin />
      </a>
      <a href="https://github.com/MahdiRaghah" target="_blank" rel="noreferrer">
        <FaGithub />
      </a>
    </div>
  );
};

export default HeaderSocials;
