import React, { useEffect } from "react";
import { Link } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";

import CV from "../../assets/cv.pdf";

const CTA = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div
      data-aos={window.innerWidth <= 600 ? "flip-up" : "fade-right"}
      data-aos-delay="1600"
      className="cta"
    >
      <a href={CV} download className="btn">
        Download CV
      </a>
      <Link className="btn btn-primary" activeClass="active" spy to="contact">
        Hire me
      </Link>
    </div>
  );
};

export default CTA;
