import React from "react";
import { Link } from "react-scroll";
import { AiOutlineHome } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiBook } from "react-icons/bi";
// import { RiServiceLine } from "react-icons/ri";
import { MdWorkOutline } from "react-icons/md";
import { BiMessageSquareDetail } from "react-icons/bi";

import "./Nav.css";

const Nav = () => {
  // const [activeNav, setActiveNav] = useState("");
  return (
    <nav>
      <Link activeClass="active" spy to="/">
        <AiOutlineHome />
      </Link>

      <Link activeClass="active" spy to="about">
        <AiOutlineUser />
      </Link>

      <Link activeClass="active" spy to="experience">
        <BiBook />
      </Link>

      <Link activeClass="active" spy to="portfolio">
        <MdWorkOutline />
      </Link>

      <Link activeClass="active" spy to="contact">
        <BiMessageSquareDetail />
      </Link>
    </nav>
  );
};

export default Nav;
