import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./Portfolio.css";
import IMG1 from "../../assets/portfolio1.jpg";
import IMG2 from "../../assets/portfolio2.jpg";
import IMG3 from "../../assets/portfolio3.jpg";
import IMG4 from "../../assets/portfolio4.jpg";
import IMG5 from "../../assets/portfolio5.jpg";
import IMG6 from "../../assets/portfolio6.jpg";

const projectsData = [
  {
    id: 1,
    image: IMG1,
    title: "Portfolio1",
    github: "https://github.com",
    demo: "https://google.com",
  },
  {
    id: 2,
    image: IMG2,
    title: "Portfolio2",
    github: "https://github.com",
    demo: "https://google.com",
  },
  {
    id: 3,
    image: IMG3,
    title: "Portfolio3",
    github: "https://github.com",
    demo: "https://google.com",
  },
  {
    id: 4,
    image: IMG4,
    title: "Portfolio4",
    github: "https://github.com",
    demo: "https://google.com",
  },
  {
    id: 5,
    image: IMG5,
    title: "Portfolio5",
    github: "https://github.com",
    demo: "https://google.com",
  },
  {
    id: 6,
    image: IMG6,
    title: "Portfolio6",
    github: "https://github.com",
    demo: "https://google.com",
  },
];

const Portfolio = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <section
      data-aos={window.innerWidth <= 600 ? "fade-right" : ""}
      id="portfolio"
    >
      <h5 data-aos="zoom-in">My Recent Projects</h5>
      <h2 data-aos="zoom-in">Portfolio</h2>

      <div
        data-aos={window.innerWidth <= 600 ? "fade-right" : ""}
        className="container portfolio__container"
      >
        {projectsData.map((project) => (
          <article
            data-aos="fade-right"
            key={project.id}
            className="portfolio__item"
          >
            <div
              data-aos={window.innerWidth <= 600 ? "zoom-in" : "flip-up"}
              data-aos-delay="1000"
              className="portfolio__item-image"
            >
              <img src={project.image} alt="Portfolio" />
            </div>

            <h3 data-aos="zoom-in" data-aos-delay="1300">
              {project.title}
            </h3>
            <div
              data-aos="flip-up"
              data-aos-delay="1300"
              className="portfolio__item-cta"
            >
              <a
                href={project.github}
                target="_blank"
                rel="noreferrer"
                className="btn"
              >
                Github
              </a>
              <a
                href={project.demo}
                target="_blank"
                rel="noreferrer"
                className="btn btn-primary"
              >
                Live Demo
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
